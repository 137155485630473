import { inject, Injectable } from '@angular/core';
import { ExperimentService } from '../utils/experiment.service';

export enum ModaOnboardingVariant {
  DEFAULT = 'default',
  // SHORT_ONBOARDING = 'short_onboarding',
}

@Injectable({
  providedIn: 'root',
})
export class ModaOnboardingExperimentService {
  private experimentService = inject(ExperimentService);
  public readonly EXPERIMENT_KEY = 'moda-onboarding';
  private readonly VARIANTS: ModaOnboardingVariant[] = Object.values(ModaOnboardingVariant);

  async startExperiment(): Promise<ModaOnboardingVariant> {
    try {
      const variant = await this.experimentService.startExperiment(this.EXPERIMENT_KEY, this.VARIANTS);
      return this.isValidVariant(variant) ? variant : ModaOnboardingVariant.DEFAULT;
    } catch (error) {
      console.error('Error starting scheduling experiment:', error);
      return ModaOnboardingVariant.DEFAULT;
    }
  }

  getVariant(): ModaOnboardingVariant {
    const variant = this.experimentService.getVariant(this.EXPERIMENT_KEY, this.VARIANTS);
    return this.isValidVariant(variant) ? variant : ModaOnboardingVariant.DEFAULT;
  }

  private isValidVariant(variant: string): variant is ModaOnboardingVariant {
    return this.VARIANTS.includes(variant as ModaOnboardingVariant);
  }
}

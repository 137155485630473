import { inject, Injectable } from '@angular/core';
import { ExperimentService } from '../utils/experiment.service';

export enum OptumVariant {
  OPTUM_INFO_SLIDE = 'optum-code-info',
  // OPTUM_SHORT_ONBOARDING = 'optum-short-onboarding',
}

@Injectable({
  providedIn: 'root',
})
export class OptumExperimentService {
  private experimentService = inject(ExperimentService);
  public readonly OPTUM_EXPERIMENT_KEY = 'optum';

  private readonly VARIANTS = [OptumVariant.OPTUM_INFO_SLIDE];

  async startExperiment(): Promise<OptumVariant> {
    try {
      const variant = await this.experimentService.startExperiment(this.OPTUM_EXPERIMENT_KEY, this.VARIANTS);

      return variant as OptumVariant;
    } catch (error) {
      console.error('Error starting scheduling experiment:', error);
      return OptumVariant.OPTUM_INFO_SLIDE;
    }
  }

  getVariant(): OptumVariant {
    return this.experimentService.getVariant(this.OPTUM_EXPERIMENT_KEY, this.VARIANTS) as OptumVariant;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppointmentReschedulePayload,
  AppointmentSchedulePayload,
  AppointmentTypeDetails,
  GetAppointmentTypeByCustomerAndProvider,
  ProviderAvailability,
  ProviderType,
  SchedulingMonthAvailability,
} from '@mightyhealth/library';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface GetAvailabilityByDate extends BypassCacheRequest {
  date: string;
  providerType?: ProviderType;
  customerId?: string;
  state?: string;
  memberId?: string;
  providerId?: string;
  timezone?: string;
  memberAppointmentId?: string;
}

interface ReschedulingGetAvailability extends Pick<Required<GetAvailabilityByDate>, 'date' | 'memberAppointmentId'> {
  timezone?: string;
  memberId?: string;
}

interface BypassCacheRequest {
  random?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentSchedulingService {
  constructor(private http: HttpClient) {}

  async getAvailabilityByDate({
    date,
    customerId = undefined,
    state = undefined,
    memberId = undefined,
    providerId = undefined,
    timezone = undefined,
    providerType = undefined,
  }: GetAvailabilityByDate) {
    const url = `${environment.API_UNAUTH_URL}/appointment/scheduling/availability/date/${date}`;
    const filter: Omit<GetAvailabilityByDate, 'date'> = {
      providerType,
      random: Math.random(),
    };

    if (customerId) {
      filter.customerId = customerId;
    }

    if (state) {
      filter.state = state;
    }

    if (memberId) {
      filter.memberId = memberId;
    }

    if (providerId) {
      filter.providerId = providerId;
    }

    if (timezone) {
      filter.timezone = timezone;
    }

    const params = new HttpParams({
      fromObject: filter,
    });

    try {
      return await lastValueFrom(this.http.get<ProviderAvailability[]>(url, { params }));
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async getAvailabilityByMonth({
    date,
    customerId = undefined,
    state = undefined,
    memberId = undefined,
    providerId = undefined,
    timezone = undefined,
    providerType = undefined,
  }: GetAvailabilityByDate) {
    const url = `${environment.API_UNAUTH_URL}/appointment/scheduling/availability/month/${date}`;
    const filter: Omit<GetAvailabilityByDate, 'date'> = {
      providerType,
      random: Math.random(),
    };

    if (customerId) {
      filter.customerId = customerId;
    }

    if (state) {
      filter.state = state;
    }

    if (memberId) {
      filter.memberId = memberId;
    }

    if (providerId) {
      filter.providerId = providerId;
    }

    if (timezone) {
      filter.timezone = timezone;
    }

    const params = new HttpParams({
      fromObject: filter,
    });

    try {
      return await lastValueFrom(this.http.get<SchedulingMonthAvailability>(url, { params }));
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async getRescheduleDateAvailability({
    date,
    memberAppointmentId,
    timezone = undefined,
    memberId = undefined,
  }: ReschedulingGetAvailability) {
    const url = `${environment.API_UNAUTH_URL}/appointment/${memberAppointmentId}/scheduling/availability/date/${date}`;
    const params = new HttpParams({
      fromObject: {
        ...(timezone && { timezone }),
        ...(memberId && { memberId }),
      },
    });

    try {
      return await lastValueFrom(this.http.get<ProviderAvailability[]>(url, { params }));
    } catch (error) {
      console.log('error getting rescheduling availability', error);
      throw error;
    }
  }

  async getRescheduleMonthAvailability({
    date,
    memberAppointmentId,
    timezone = undefined,
    memberId = undefined,
  }: ReschedulingGetAvailability) {
    const url = `${environment.API_UNAUTH_URL}/appointment/${memberAppointmentId}/scheduling/availability/month/${date}`;
    const params = new HttpParams({
      fromObject: {
        ...(timezone && { timezone }),
        ...(memberId && { memberId }),
      },
    });

    try {
      return await lastValueFrom(this.http.get<SchedulingMonthAvailability>(url, { params }));
    } catch (error) {
      console.log('error getting rescheduling availability', error);
      throw error;
    }
  }

  async create(payload: Partial<AppointmentSchedulePayload>) {
    const url = `${environment.API_UNAUTH_URL}/appointment/schedule`;
    return await lastValueFrom(this.http.post<AppointmentSchedulePayload>(url, payload));
  }

  async reschedule(payload: AppointmentReschedulePayload) {
    const { memberAppointmentId: id, ...params } = payload;
    const url = `${environment.API_UNAUTH_URL}/appointment/${id}/reschedule`;
    return await lastValueFrom(this.http.post<AppointmentReschedulePayload>(url, params));
  }

  async getType({ customerId, state, providerType }: GetAppointmentTypeByCustomerAndProvider) {
    const url = `${environment.API_UNAUTH_URL}/appointment/type`;
    const filter: Omit<GetAvailabilityByDate, 'date'> = {
      providerType,
      state,
      customerId,
    };

    const params = new HttpParams({
      fromObject: filter,
    });

    try {
      return await lastValueFrom(this.http.get<AppointmentTypeDetails>(url, { params }));
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }
}

import { environment } from './environment';
import { EnvironmentName } from './environment-name';

export type EnvironmentDef = {
  production: boolean;
  environmentName: string;
  API_URL: string;
  API_UNAUTH_URL: string;
  API_UNAUTH_LEGACY_URL: string;
  BASE_URL: string;
  SEGMENT_KEY: string;
  STRIPE_KEY: string;
  GA_MEASUREMENT_ID: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  KLAVIYO_CONFIG: {
    url: string;
    publicApiKey: string;
  };
  SENTRY_DSN: string;
  ACUITY_APPT_TYPE: string;
  BRIDGE_CONFIG: {
    PUB_KEY: string;
    INTAKE_CALL_SERVICE_TYPE_ID: string;
  };
  ZOOM_MEETING_SDK_KEY: string;
};

type EnvironmentProps = keyof EnvironmentDef;

export class Environments {
  static isProd(): boolean {
    return environment.environmentName === EnvironmentName.PROD;
  }

  static isDemo(): boolean {
    return environment.environmentName === EnvironmentName.DEMO;
  }

  static isDev(): boolean {
    return environment.environmentName === EnvironmentName.DEV;
  }

  static getProp(key: EnvironmentProps) {
    return environment[key];
  }

  static getConfig(): EnvironmentDef {
    return environment;
  }
}

import { inject, Injectable } from '@angular/core';
import { AppointmentSchedulePayload, Consent, Provider, ProviderPrefix, ProviderType } from '@mightyhealth/library';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppointmentSchedulingService } from 'src/app/apis/appointment-scheduling.service';
import { MemberConsentsService } from 'src/app/apis/member-consents.service';
import { TrackerService } from 'src/app/utils/tracking/tracker.service';
import { WebTrackignEvents } from 'src/app/utils/tracking/web-tracking-events';

export interface SchedulingFormData {
  datetime?: string;
  appointmentType?: string | number;
  timezone?: string;
  customerId?: string;
  state?: string;
  provider?: Provider;
  memberId?: string;
  duration?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingService {
  private schedulingSub = new BehaviorSubject<SchedulingFormData | null>(null);
  private consentsSub = new BehaviorSubject<Consent[]>([]);
  schedulingData$ = this.schedulingSub.asObservable();
  consentData$ = this.consentsSub.asObservable();

  apptSchedulingService = inject(AppointmentSchedulingService);
  memberConsentsService = inject(MemberConsentsService);
  trackerService = inject(TrackerService);

  setSchedulingData(data: Partial<SchedulingFormData>) {
    this.schedulingSub.next({
      ...this.schedulingSub.value,
      ...data,
    });
  }

  getSchedulingData(): SchedulingFormData | null {
    return this.schedulingSub.value;
  }

  async fetchPendingConsents(customerId: string, memberId: string) {
    return this.memberConsentsService.getPending(customerId, memberId);
  }

  setConsents(consents: Consent[]) {
    return this.consentsSub.next(consents);
  }

  getConsents() {
    return this.consentsSub.value;
  }

  async scheduleAppointment() {
    try {
      const formData = this.getSchedulingData();

      if (!formData) {
        return;
      }
      const { datetime, appointmentType, timezone, provider, memberId } = formData;
      const payload: Partial<AppointmentSchedulePayload> = {
        datetime: datetime as string,
        appointmentType: appointmentType as string,
        providerId: provider?.id as string,
        timezone: timezone as string,
        memberId: memberId as string,
      };

      await this.apptSchedulingService.create(payload);
      await this.trackerService.track(WebTrackignEvents.WEB_SCHEDULED);
    } catch (error) {
      console.log('error', error);
    }
  }

  finish() {
    this.schedulingSub.next(null);
  }
}

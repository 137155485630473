import { chronicConditions } from './chronic-conditions.const';

const enum ONBOARDING_STEPS_DEFINITION {
  INTRO = 'intro',
  HEALTH_GOAL = 'health-goal',
  HEALTH_TESTIMONIAL = 'health-testimonial',
  HEALTH_GOAL_REASON = 'health-goal-reason',
  PROGRAM_DESIGNED_FOR_YOU = 'program-designed-for-you',
  YOUR_HEALTH_IS_INTERCONNECTED = 'your-health-is-interconnected',
  PROGRAM_SUPPORT = 'program-support',
  CREATING_PROGRAM = 'creating-program',
  HEALTH_INSURANCE = 'health-insurance',
  DATE_OF_BIRTH = 'date-of-birth',
  SIGNUP = 'signup',
  FIRST_STEPS = 'first-steps',
  TWELVE_WEEK_PROGRAM = 'twelve-week-program',
  SOFT_ELIGIBILITY = 'soft-eligibility',
  MODA_INSURANCE_CONFIRMATION = 'moda-insurance-confirmation',
  WEIGHT_LOSS_PROGRAM_STEP_1 = 'weight-loss-program-step-1',
  WEIGHT_LOSS_PROGRAM_STEP_2 = 'weight-loss-program-step-2',
  WEIGHT_LOSS_PROGRAM_STEP_3 = 'weight-loss-program-step-3',
}

/*
 From landing page steps definitions
 */
export const ONBOARDING_STEPS_DEFINITION_OPTUM = [
  ONBOARDING_STEPS_DEFINITION.INTRO,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_TESTIMONIAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL_REASON,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_DESIGNED_FOR_YOU,
  ONBOARDING_STEPS_DEFINITION.YOUR_HEALTH_IS_INTERCONNECTED,
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_SUPPORT,
  ONBOARDING_STEPS_DEFINITION.CREATING_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.HEALTH_INSURANCE,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
];

export const ONBOARDING_STEPS_DEFINITION_MARSHFIELD = [
  ONBOARDING_STEPS_DEFINITION.INTRO,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_TESTIMONIAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL_REASON,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_DESIGNED_FOR_YOU,
  ONBOARDING_STEPS_DEFINITION.YOUR_HEALTH_IS_INTERCONNECTED,
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_SUPPORT,
  ONBOARDING_STEPS_DEFINITION.CREATING_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
];

export const ONBOARDING_STEPS_DEFINITION_CHAPTER = [...ONBOARDING_STEPS_DEFINITION_MARSHFIELD];

export const ONBOARDING_STEPS_DEFINITION_MODA = [
  ONBOARDING_STEPS_DEFINITION.INTRO,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_TESTIMONIAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL_REASON,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_DESIGNED_FOR_YOU,
  ONBOARDING_STEPS_DEFINITION.YOUR_HEALTH_IS_INTERCONNECTED,
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_SUPPORT,
  ONBOARDING_STEPS_DEFINITION.CREATING_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
  ONBOARDING_STEPS_DEFINITION.HEALTH_INSURANCE,
];

export const ONBOARDING_STEPS_DEFINITION_MODA_INSURANCE_CONFIRMATION = [
  ONBOARDING_STEPS_DEFINITION.INTRO,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_TESTIMONIAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL_REASON,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_DESIGNED_FOR_YOU,
  ONBOARDING_STEPS_DEFINITION.YOUR_HEALTH_IS_INTERCONNECTED,
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_SUPPORT,
  ONBOARDING_STEPS_DEFINITION.CREATING_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.MODA_INSURANCE_CONFIRMATION,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
  ONBOARDING_STEPS_DEFINITION.HEALTH_INSURANCE,
];

export const ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE = [
  ONBOARDING_STEPS_DEFINITION.FIRST_STEPS,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_TESTIMONIAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL_REASON,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_DESIGNED_FOR_YOU,
  ONBOARDING_STEPS_DEFINITION.YOUR_HEALTH_IS_INTERCONNECTED,
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_SUPPORT,
  ONBOARDING_STEPS_DEFINITION.CREATING_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SOFT_ELIGIBILITY,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
];

export const ONBOARDING_STEPS_DEFINITION_PROGRAM_PAGE = [
  ONBOARDING_STEPS_DEFINITION.TWELVE_WEEK_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_TESTIMONIAL,
  ONBOARDING_STEPS_DEFINITION.HEALTH_GOAL_REASON,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_DESIGNED_FOR_YOU,
  ONBOARDING_STEPS_DEFINITION.YOUR_HEALTH_IS_INTERCONNECTED,
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  ONBOARDING_STEPS_DEFINITION.PROGRAM_SUPPORT,
  ONBOARDING_STEPS_DEFINITION.CREATING_PROGRAM,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SOFT_ELIGIBILITY,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
];

export const ONBOARDING_STEPS_DEFINITION_DIRECT = [
  ONBOARDING_STEPS_DEFINITION.INTRO,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SOFT_ELIGIBILITY,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
  ONBOARDING_STEPS_DEFINITION.HEALTH_INSURANCE,
];

export const ONBOARDING_STEPS_DEFINITION_WEIGHT_LOSS_SHORT_FUNNEL = [
  ONBOARDING_STEPS_DEFINITION.WEIGHT_LOSS_PROGRAM_STEP_1,
  ONBOARDING_STEPS_DEFINITION.WEIGHT_LOSS_PROGRAM_STEP_2,
  ONBOARDING_STEPS_DEFINITION.WEIGHT_LOSS_PROGRAM_STEP_3,
  ONBOARDING_STEPS_DEFINITION.SOFT_ELIGIBILITY,
  ONBOARDING_STEPS_DEFINITION.DATE_OF_BIRTH,
  ONBOARDING_STEPS_DEFINITION.SIGNUP,
];

import { inject, Injectable } from '@angular/core';
import { ExperimentService } from '../utils/experiment.service';
import {
  OnboardingVariant,
  WEB_ONBOARDING_MODA_INSURANCE_CONFIRMATION,
  WEB_ONBOARDING_OPTUM_MODA,
} from './onboarding-experiment.service';

export enum ModaVariant {
  REDUCED = 'reduced',
  REDUCED_INSURANCE_CONFIRMATION = 'reduced_insurance_confirmation',
}

@Injectable({
  providedIn: 'root',
})
export class ModaExperimentService {
  private experimentService = inject(ExperimentService);
  public readonly EXPERIMENT_KEY = 'moda-experiment';
  private readonly VARIANTS: ModaVariant[] = Object.values(ModaVariant);

  async startExperiment(): Promise<ModaVariant> {
    try {
      const variant = await this.experimentService.startExperiment(this.EXPERIMENT_KEY, this.VARIANTS);
      return this.isValidVariant(variant) ? variant : ModaVariant.REDUCED;
    } catch (error) {
      console.error('Error starting scheduling experiment:', error);
      return ModaVariant.REDUCED;
    }
  }

  getVariant(): ModaVariant {
    const variant = this.experimentService.getVariant(this.EXPERIMENT_KEY, this.VARIANTS);
    return this.isValidVariant(variant) ? variant : ModaVariant.REDUCED;
  }

  getOnboardingVariant(): OnboardingVariant {
    const variant = this.getVariant();
    const variantMapping: Record<ModaVariant, OnboardingVariant> = {
      [ModaVariant.REDUCED]: WEB_ONBOARDING_OPTUM_MODA,
      [ModaVariant.REDUCED_INSURANCE_CONFIRMATION]: WEB_ONBOARDING_MODA_INSURANCE_CONFIRMATION,
    };

    return variantMapping[variant as ModaVariant] || WEB_ONBOARDING_OPTUM_MODA;
  }

  private isValidVariant(variant: unknown): variant is ModaVariant {
    return this.VARIANTS.includes(variant as ModaVariant);
  }
}

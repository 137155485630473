import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { MightyComponentsModule } from '@mightyhealth/angular-web-components';
import * as Sentry from '@sentry/angular-ivy';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { BannerComponent } from './components/banner/banner.component';
import { ComponentsModule } from './components/components.module';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { TransferStateInterceptorService } from './interceptors/transfer-state.interceptor';
import { JoinComponent } from './pages/join/join.component';
import { ModaExperimentService } from './services/moda-experiment.service';
import { ModaOnboardingExperimentService } from './services/moda-onboarding-experiment.service';
import { OnboardingExperimentService } from './services/onboarding-experiment.service';
import { OptumExperimentService } from './services/optum-experiment.service';
import { ExperimentGuard } from './utils/experiments.guard';
import { QueryParamsDirective } from './utils/query-params.directive';
import { RerenderDirective } from './utils/rerender.directive';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: (globalThis as any).window?.location?.hostname || environment.BASE_URL,
  },
  position: 'bottom',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#f7f6ef',
      text: '#415364',
      link: '#ffffff',
    },
    button: {
      background: '#dc8468',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    link: 'Learn more',
    href: 'https://docs.google.com/document/d/1Mw8DIfvyxR_Viql9JsPpFCoeQLizE44Yv-EjUq1jUoU/edit?usp=sharing',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [AppComponent, QueryParamsDirective, JoinComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    FormsModule,
    InfiniteScrollModule,
    MightyComponentsModule,
    RerenderDirective,
    NgxGoogleAnalyticsModule.forRoot(environment.GA_MEASUREMENT_ID),
    NgxGoogleAnalyticsRouterModule,
    BannerComponent,
    NgcCookieConsentModule.forRoot(cookieConfig),
    QuillModule.forRoot(),
  ],
  providers: [
    DatePipe,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      return auth;
    }),
    provideRemoteConfig(() => getRemoteConfig()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptorService,
      multi: true,
    },
    ExperimentGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          onboardingExperimentService: OnboardingExperimentService,
          optumValidationExperiment: OptumExperimentService,
          modaExperimentService: ModaExperimentService,
          modaOnboardingExperimentService: ModaOnboardingExperimentService
        ) =>
        async () =>
          await Promise.all([
            onboardingExperimentService.startOnboardingExperiment(),
            optumValidationExperiment.startExperiment(),
            modaExperimentService.startExperiment(),
            modaOnboardingExperimentService.startExperiment(),
          ]),
      deps: [
        OnboardingExperimentService,
        OptumExperimentService,
        ModaExperimentService,
        ModaOnboardingExperimentService,
      ],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
